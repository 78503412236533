import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import { motion } from "motion/react";
import bgOne from '../Assets/Images/bgOne.jpg'


function Discover() {
  return (
    
  
    <section>
      <NavBar />
      
     
      <motion.section md="12" className="text-center"
           initial={{
            opacity:0, 
      
            
          }}
         whileInView={{
           opacity:1, 
     
         }}
         viewport={{
        once: true,
          
         }}
         transition={{
          ease: "linear",
          duration: 1.6,
          x: { duration: 1.9 }
         }}
          >
            <div className="relative h-[400px] w-full  bg-gradient-to-br overflow-hidden from-gray-500 to-gray-900">
              <Card.Img  src={bgOne} className="absolute h-[400px] w-full object-cover mix-blend-overlay hover:scale-[1.05] ease-in-out duration-500"/>
          
            </div>
          </motion.section>
                
                <motion.section
                initial={{
                  opacity:0, 
                  
                }}
               whileInView={{
                 opacity:1, 
                
               }}
               viewport={{
              once: true,
                
               }}
               transition={{
                ease: "linear",
                duration: 1.5,
                x: { duration: 1.5 }
               }} 
                 className="md:flex mx-auto container mb-4">
                  <div className="bg-white w-full md:w-[60%] p-4">
                <h4 className="text-xl font-semibold mx-3 logo-txt-color mt-4 text-center">
                  ABOUT AGROROUTE
                </h4>
                <Card.Text className="custom-font-19 mt-2 mb-3 text-justify p-3  ">
                  Agroroute Nepal Pvt Ltd. has been established to strengthen
                  Agri-cooperatives, farmer organizations, SMEs and other
                  organizations in agribusiness under the slogan “Accelerating
                  Agribusiness”. Agroroute Nepal follows the footsteps of the
                  work of Agriterra in Nepal. Agriterra Nepal has since the
                  start of activities in Nepal in 2001 gained a strong
                  reputation in the field of strengthening farmer organizations
                  in Nepal. Due to a shift in available subsidies from external
                  donors, the former staff members of Agriterra Nepal found a
                  modality to continue strengthening farmer organizations by
                  establishing their own company Agroroute; and linking it to
                  the globally active organization of Agriterra via a Memorandum
                  of Understanding. Agroroute Nepal sees it as an opportunity to
                  support, collaborate and coordinate with a wider range of
                  stakeholders towards strengthening the governance, financial
                  management, agri-business and institutional development of
                  farmer organizations, cooperatives and SMEs through training
                  and advice. Through the Memorandum of Understanding between
                  Agriterra and Agroroute Nepal, Agroroute can guarantee the
                  quality of services provided, with access to Agriterra’ s best
                  practices.
                </Card.Text>
                </div>

                <div className="bg-white  w-full md:w-[60%] p-4">
                  <h4 className="text-2xl font-semibold mt-3 text-emerald-600 text-center">
                  Do you need more information <br/> or do you have questions?
                  </h4>
                  <ul className="my-4 text-center text-xl text">
                    <li className="place-content-center items-center text-emerald-800 py-2">
                    <i class="bi bi-envelope"></i>&nbsp; agroroute@agroroutenepal.org
                    </li>

                    <li className="place-content-center items-center py-2 text-emerald-800">
                    <i class="bi bi-geo-alt"></i>&nbsp; 2nd Floor Oasis building Patan Dhoka, Lalitpur Nepal
                    </li>

                    <li className="place-content-center items-center py-2 text-emerald-800 text-4xl gap-4 flex mt-3">
                   <a href="https://www.facebook.com/p/Agroroute-Nepal-100077972273793/" > <i className="bi bi-facebook text-blue-700 hover:text-sky-500 duration-300 ease-in-out"></i> </a>
                   <a href="https://np.linkedin.com/company/agroroute-nepal" > <i class="bi bi-linkedin text-sky-600 hover:text-sky-400 duration-300 ease-in-out"></i></a>
                   <a href="whatsapp:contact=015555555555@s.whatsapp.com&message='I'd like to chat with you'" > <i class="bi bi-whatsapp text-green-600 hover:text-emerald-400 duration-300 ease-in-out"></i></a>
                    </li>

                  </ul>
                </div>
                </motion.section>
             

             <motion.section
             initial={{
              opacity:0, 
              
            }}
           whileInView={{
             opacity:1, 
            
           }}
           viewport={{
          once: true,
            
           }}
           transition={{
            ease: "linear",
            duration: 1.2,
            x: { duration: 1.8 }
           }}  
           className='mx-auto container mb-4'>

         
        <Row>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "20rem" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3 font-semibold  logo-txt-color ">
                  VISION
                </Card.Title>
                <Card.Text className="text-left custom-font-19 text-emerald-700 mt-2 mb-3 p-3 flex gap-2">
                <i className="bi bi-caret-right-fill text-emerald-700"></i> <p>  Professional and competitive farmer organizations in Nepal</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "20rem" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3 font-semibold logo-txt-color">
                  MISSION
                </Card.Title>
                <Card.Text className="text-left custom-font-19 text-emerald-700 mt-2 mb-3 p-3  flex gap-2">
                <i className="bi bi-caret-right-fill text-emerald-700"></i> <p> Providing high quality, demand driven and customized capacity
                  building services, building upon the collective strength of
                  farmer organizations that are vital in triggering economic</p>
                  development in rural areas.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "20rem" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3 custom-font-19 logo-txt-color font-semibold">
                  FOCUS
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3 text-emerald-700  flex gap-2">
                <i className="bi bi-caret-right-fill text-emerald-700"></i>   <p>  Farmer Organization Business Development, Sustainable
                  Production and Productivity, Organisational Capacity of Farmer
                  Organization</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </motion.section>
   
      <Footer />
    </section>
  );
}
export default Discover;
