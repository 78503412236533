import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../../../url";
import AdminNavBar from "../../../Components/Navbar/AdminNavBar";
import { Link } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";

import AddUser from "./AddUser";

import EditUser from "./EditUser";
import CloseIcon from "@mui/icons-material/Close";

function AdminUsers() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [iconCloseId, setIconCloseId] = useState();

  const token = localStorage.getItem("authToken");

  const fetchNews = () => {
    axios
      .get(`${url}/user`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      }) // Replace with your API endpoint
      .then((response) => {
        setUsers(response.data); // Fetch the first 10 news items for demo purposes
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error Fetching Users",
          text:
            error.message ||
            "An unexpected error occurred while fetching news.",
        });
      });
  };

  useEffect(() => {
    fetchNews();
  }, []);

  async function deleteIcon(id) {
    try {
      const deleteRes = await axios.delete(`${url}/user/deleteUser`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });
      Swal.fire({
        icon: "success",
        title: "User Deleted",
        text: "User has been successfully deleted!",
      });

      fetchNews();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error Deleting User",
        text:
          error.message || "An unexpected error occurred while deleting news.",
      });
    }
  }

  return (
    <div className="adminHome">
      <AdminNavBar />

      <ul className="mt-10">
        {users.map((item, idx) => (
          <li
            key={item.id}
            className="flex justify-between items-center mb-4 bg-gray-700 p-4 rounded"
          >
            <span className>{idx + 1}</span>

            <span>{item.username}</span>

            <div className="ml-4 flex gap-2">
              <Link to={`/admin/editUser/${item.id}`}>
                <button
                  onClick={() => {
                    setSelectedUser(item);
                    setIconCloseId(item.id);
                  }}
                  className="p-2 hover:bg-gray-700 text-white rounded"
                >
                  <EditIcon />
                </button>
              </Link>
              <button
                className="p-2 bg-red-500 hover:bg-red-700 text-white rounded"
                onClick={() => deleteIcon(item.id)}
              >
                <DeleteIcon />
              </button>
            </div>
          </li>
        ))}
      </ul>

      {selectedUser && <EditUser userData={selectedUser} />}
      <AddUser />
    </div>
  );
}

export default AdminUsers;
