import React, { useState } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import AdminNavBar from "../../../Components/Navbar/AdminNavBar";
import { url } from "../../../url";

const AddUser = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const token = localStorage.getItem("authToken");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    if (!username || !password || !role) {
      setError("Please fill in all fields.");
      return;
    }

    let isAdmin = false;

    if (role === "ADMIN") isAdmin = true;
    else isAdmin = false;

    const userData = {
      username,
      password,
      isAdmin,
    };

    try {
      const response = await axios.post(`${url}/user/createUser`, userData, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });
      setMessage("User added successfully!");
      setError("");

      // Reset form fields
      setUsername("");
      setPassword("");
      setRole("");
    } catch (err) {
      setError("Error adding user.");
      setMessage("");
    }
  };

  return (
    <div className="adminHome">
      <Container className="mt-5 p-4">
        <h2 className="text-center mb-4 text-white">Add New User</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="username" className="mb-3">
            <Form.Label className="text-white font-weight-bold">
              Username
            </Form.Label>
            <Form.Control
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter username"
            />
          </Form.Group>

          <Form.Group controlId="password" className="mb-3">
            <Form.Label className="text-white font-weight-bold">
              Password
            </Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
            />
          </Form.Group>

          <Form.Group controlId="role" className="mb-3">
            <Form.Label className="text-white font-weight-bold">
              Role
            </Form.Label>
            <Form.Control
              as="select"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="">Select role</option>
              <option value="ADMIN">Admin</option>
              <option value="USER">Normal User</option>
            </Form.Control>
          </Form.Group>

          <Button variant="primary" type="submit">
            Add User
          </Button>
        </Form>

        {message && (
          <Alert variant="success" className="mt-3">
            {message}
          </Alert>
        )}
        {error && (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        )}
      </Container>
    </div>
  );
};

export default AddUser;
