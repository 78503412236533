import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../../../url";
import AdminNavBar from "../../../Components/Navbar/AdminNavBar";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import AddPhotos from "./AddPhotos";

function AdminPhotos() {
  const [photos, setPhotos] = useState([]);

  const token = localStorage.getItem("authToken");

  const fetchNews = () => {
    axios
      .get(`${url}/photo/all`) // Replace with your API endpoint
      .then((response) => {
        setPhotos(response.data); // Fetch the first 10 news items for demo purposes
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error Fetching News",
          text:
            error.message ||
            "An unexpected error occurred while fetching news.",
        });
      });
  };

  useEffect(() => {
    fetchNews();
  }, []);

  async function deleteIcon(id) {
    try {
      const deleteRes = await axios.delete(`${url}/photo/delete/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      Swal.fire({
        icon: "success",
        title: "Photo Deleted",
        text: "Photo has been successfully deleted!",
      });

      fetchNews();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error Deleting Photo",
        text:
          error.message || "An unexpected error occurred while deleting news.",
      });
    }
  }

  return (
    <div className="adminHome">
      <AdminNavBar />

      {/* Photos List */}
      <ul className="mt-10">
        {photos.map((item, idx) => (
          <li
            key={item.id}
            className="flex justify-between items-center mb-4 bg-gray-700 p-4 rounded"
          >
            <span className>{idx + 1}</span>

            <img src={item.image} alt="" height={100} width={100} />

            <div className="ml-4 flex gap-2">
              <button
                className="p-2 bg-red-500 hover:bg-red-700 text-white rounded"
                onClick={() => deleteIcon(item.id)}
              >
                <DeleteIcon />
              </button>
            </div>
          </li>
        ))}
      </ul>

      <AddPhotos />
    </div>
  );
}

export default AdminPhotos;
