import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import farm from '../Assets/Images/farm.jpg'
import { motion } from "motion/react";

function Discover2() {
  return (
    <section 
    
   >
      <NavBar />
      <motion.section md="12" className="text-center"
           initial={{
            opacity:0, 
         
            
          }}
         whileInView={{
           opacity:1, 
       
         }}
         viewport={{
        once: true,
          
         }}
         transition={{
          ease: "linear",
          duration: 1.6,
          x: { duration: 1.9 }
         }}
          >
            <div className="relative h-[400px] w-full  bg-gradient-to-br overflow-hidden from-gray-500 to-gray-900">
              <Card.Img  src={farm} className="absolute h-[400px] w-full object-cover mix-blend-overlay hover:scale-[1.05] ease-in-out duration-500"/>
          
            </div>
          </motion.section>

                <motion.section
                initial={{
                  opacity:0, 
            
                  
                }}
               whileInView={{
                 opacity:1, 
          
               }}
               viewport={{
              once: true,
                
               }}
               transition={{
                ease: "linear",
                duration: 1.2,
                x: { duration: 1.8 }
               }}
                 className="md:flex my-5 mx-auto container">
                  <div className="md:w-1/2 w-full bg-slate-100 p-4">
                <Card.Title className=" mx-2 logo-txt-color text-center font-semibold">
                  THEORY OF CHANGE
                </Card.Title>
                <Card.Text className=" custom-font-19 mt-2 mb-3 p-3 text-justify">
                  Agroroute believes that investing in professional farmer
                  organizations, cooperatives, and small medium enterprises lead
                  to a stable and prosperous Agri-sector and economy. Ambitious
                  farmers in emerging economies want to develop themselves,
                  become entrepreneurs and business partners, create added
                  value, and move up the supply chain. Supporting these farmers
                  with training, advice and exchange creates jobs for a large
                  group of people who would rather not be farmers but want to
                  stay in rural areas. As such, farmer organizations,
                  cooperatives small medium enterprises are ideally equipped to
                  lead the gradual process of non-disruptive structural
                  transformation of rural areas.
                </Card.Text>
                </div>

                <div className="md:w-1/2 w-full ">
                <iframe className="w-full h-[400px]"
       
        src="https://www.youtube.com/embed/gYfSwXfVjw0" 
        title="Theory of Change" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
    </iframe>
                </div>
                </motion.section>
           
      <Footer />
    </section>
  
  );
}

export default Discover2;
