import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import farmer from '../Assets/Images/farmer.jpg'
import { motion } from "motion/react";

function Discover5() {
  return (
    <section>
      <NavBar />
      <motion.section md="12" className="text-center"
           initial={{
            opacity:0, 
      
            
          }}
         whileInView={{
           opacity:1, 
     
         }}
         viewport={{
        once: true,
          
         }}
         transition={{
          ease: "linear",
          duration: 1.6,
          x: { duration: 1.9 }
         }}
          >
            <div className="relative h-[400px] w-full  bg-gradient-to-br overflow-hidden from-gray-500 to-gray-900">
              <Card.Img  src={farmer} className="absolute h-[400px] w-full object-cover mix-blend-overlay hover:scale-[1.05] ease-in-out duration-500"/>
          
            </div>
          </motion.section>
        
        <motion.section
         initial={{
          opacity:0, 
         
          
        }}
       whileInView={{
         opacity:1, 
       
       }}
       viewport={{
      once: true,
        
       }}
       transition={{
        ease: "linear",
        duration: 1.2,
        x: { duration: 1.5 }
       }}>

    
      <Container className="my-8 p-3 bg-light ring-1 ring-green-800 rounded-md mx-auto container" fluid>
        
        
                <Card.Title className="text-center font-semibold text-2xl mx-3 logo-txt-color ">
                  TARGET GROUPS
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 text-lg text-green-800 mx-4 p-3">
                  <li>
                    Agriculture Cooperatives including primary cooperatives,
                    District cooperative unions and national level cooperative
                    federation(s)
                  </li>
                  <li>
                    Farmers organizations, including federations and unions
                  </li>
                  <li>Small Medium Enterprises</li>
                  <li>Farmers' group</li>
                  <br />
                  Through farmers’ organisations, cooperatives and SMEs, the
                  ultimate target groups of Agroroute are individual farmers,
                  agricultural entrepreneurs, women and youths.
                </Card.Text>
           
       
      </Container>
      </motion.section>
      <Footer />
    </section>
  );
}

export default Discover5;
