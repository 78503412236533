import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/Navbar/NavBar";
import { Row, Col, Card } from "react-bootstrap";
import yanamani from "../Assets/yanamani.jpg";
import rijan from "../Assets/rijan.jpg";
import swechchha from "../Assets/swechchha.jpg";
import trijan from "../Assets/trijan.jpg";
import sajena from "../Assets/sajena.jpg";
import { motion } from "motion/react";
import team from "../Assets/Images/team.jpg"

function Teams() {
  return (
    <section >
      <NavBar />
      
     
      <motion.section md="12" className="text-center"
           initial={{
            opacity:0, 
           y:0
            
          }}
         whileInView={{
           opacity:1, 
         y:1
         }}
         viewport={{
        once: true,
          
         }}
         transition={{
          ease: "linear",
          duration: 1.6,
          x: { duration: 1.9 }
         }}
          >
            <div className="relative h-[300px] w-full  bg-gradient-to-br overflow-hidden from-gray-500 to-gray-900">
              <Card.Img  src={team} className="absolute h-[300px] w-full object-cover mix-blend-overlay hover:scale-[1.05] ease-in-out duration-500"/>
            <h3 className="text-2xl font-semibold text-gray-300 mt-[100px] md:mt-[130px] relative  uppercase">Behind the Vision: Meet Our Extraordinary Team</h3>
            </div>
          </motion.section>

          <Row className="mx-auto container">
           
              <motion.h3
                initial={{
                  opacity:0, 
            
                  
                }}
               whileInView={{
                 opacity:1, 
           
               }}
               viewport={{
              once: true,
                
               }}
               transition={{
                ease: "linear",
                duration: 1,
                x: { duration: 1.2 }
               }}
               className="logo-txt-color text-center text-2xl font-semibold my-5">Meet a dynamic team of innovators, creators, and achievers, ready to inspire</motion.h3>
           
      
        
             <motion.section
               initial={{
                opacity:0, 
          
                
              }}
             whileInView={{
               opacity:1, 
         
             }}
             viewport={{
            once: true,
              
             }}
             transition={{
              ease: "linear",
              duration: 1.3,
              x: { duration: 1.7 }
             }}
              className="grid md:grid-cols-4 grid-cols-1 mb-5">         

{/* 1st */}
            <section className="flex justify-center  ">
              <div className="mt-3 mb-3 md:mx-5 w-[300px]  ring-1 ring-cyan-800 shadow-md ">
                <Card className="bg-gray-200 h-[300px]">
                  <Card.Img className="rounded-full my-3 ring-1 ring-green-700 py-2 object-scale-down w-[200px] h-[200px] md:w-[250px] md:h-[250px] bg-white flex justify-center place-content-center mx-auto " variant="top" src={trijan} />
                </Card>
                <Card className="txt-bg py-4">
                  <Card.Title className="text-center text-emerald-900 font-semibold mb-3 underline">Trijan Singh</Card.Title>
                  <div className="flex gap-4 justify-center mx-auto place-content-center items-center">
                  <Card.Link
                    class="text-3xl text-blue-700 text-center hover:text-cyan-500 ease-in-out duration-300 py-1"
                    href="https://www.linkedin.com/in/trijan-singh-9ba36251/" target="_blank"
                  >
                  <i class="bi bi-linkedin"></i> 
                  </Card.Link>
                  <Card.Link class="text-3xl text-green-700 text-center hover:text-cyan-500 ease-in-out duration-300 py-1" href="https://wa.me/9779841350142 " target="_blank">
                  <i class="bi bi-whatsapp"></i> 
                  </Card.Link>
                  <Card.Link
                    class="text-4xl text-red-700 text-center hover:text-cyan-500 ease-in-out duration-300 py-1data"
                    href="mailto:trijan.singh@agroroutenepal.org" target="_blank"
                  >
                    <i class="bi bi-envelope"></i>
                  </Card.Link>
                  </div>
                  <p className="text-center mt-2 text-lg font-semibold text-cyan-800">Connect with me</p>
                </Card>
              </div>
              </section>


              {/* 2nd */}
              <section className="flex justify-center  ">
              <div className="mt-3 mb-3 md:mx-5 w-[300px]  ring-1 ring-cyan-800 shadow-md">
                <Card className="bg-gray-200 h-[300px]">
                  <Card.Img className="rounded-full my-3 ring-1 ring-green-700 py-2 object-scale-down w-[200px] h-[200px] md:w-[250px] md:h-[250px] bg-white flex justify-center place-content-center mx-auto " variant="top" 
                  src={yanamani} />
                </Card>
                <Card className="txt-bg py-4">
                  <Card.Title className="text-center text-emerald-900 font-semibold mb-3 underline">Yanamani Nepal</Card.Title>
                  <div className="flex gap-4 justify-center mx-auto place-content-center items-center">
                  <Card.Link
                    class="text-3xl text-blue-700 text-center hover:text-cyan-500 ease-in-out duration-300 py-1"
                    href="https://www.linkedin.com/in/yanamani-nepal-468a59130/" target="_blank"
                  >
                  <i class="bi bi-linkedin"></i> 
                  </Card.Link>
                  <Card.Link class="text-3xl text-green-700 text-center hover:text-cyan-500 ease-in-out duration-300 py-1" href="https://wa.me/9779841976172"
                  target="_blank">
                  <i class="bi bi-whatsapp"></i> 
                  </Card.Link>
                  <Card.Link
                    class="text-4xl text-red-700 text-center hover:text-cyan-500 ease-in-out duration-300 py-1data"
                    href="mailto:yanamani.nepal@agroroutenepal.org" target="_blank"
                  >
                    <i class="bi bi-envelope"></i>
                  </Card.Link>
                  </div>
                  <p className="text-center mt-2 text-lg font-semibold text-cyan-800">Connect with me</p>
                </Card>
              </div>
              </section>
              {/* 3rd */}

              <section className="flex justify-center  ">
              <div className="mt-3 mb-3 md:mx-5 w-[300px]  ring-1 ring-cyan-800 shadow-md">
                <Card className="bg-gray-200 h-[300px]">
                  <Card.Img className="rounded-full my-3 ring-1 ring-green-700 py-2 object-scale-down w-[200px] h-[200px] md:w-[250px] md:h-[250px] bg-white flex justify-center place-content-center mx-auto " 
                  variant="top" src={rijan} />
                </Card>
                <Card className="txt-bg py-4">
                  <Card.Title className="text-center text-emerald-900 font-semibold mb-3 underline">Rijan Ojha</Card.Title>
                  <div className="flex gap-4 justify-center mx-auto place-content-center items-center">
                  <Card.Link
                    class="text-3xl text-blue-700 text-center hover:text-cyan-500 ease-in-out duration-300 py-1"
                    href="https://www.linkedin.com/in/rijan-ojha-280b11ab/ "
                  >
                  <i class="bi bi-linkedin"></i> 
                  </Card.Link>
                  <Card.Link class="text-3xl text-green-700 text-center hover:text-cyan-500 ease-in-out duration-300 py-1" href="https://wa.me/977980108945">
                  <i class="bi bi-whatsapp"></i> 
                  </Card.Link>
                  <Card.Link
                    class="text-4xl text-red-700 text-center hover:text-cyan-500 ease-in-out duration-300 py-1data"
                    href="mailto:ojha@agroroutenepal.org"
                  >
                    <i class="bi bi-envelope"></i>
                  </Card.Link>
                  </div>
                  <p className="text-center mt-2 text-lg font-semibold text-cyan-800">Connect with me</p>
                </Card>
              </div>
              </section>

              {/* 4th */}
              <section className="flex justify-center  ">
              <div className="mt-3 mb-3 md:mx-5 w-[300px]  ring-1 ring-cyan-800 shadow-md">
                <Card className="bg-gray-200 h-[300px]">
                  <Card.Img className="rounded-full my-3 ring-1 ring-green-700 py-2 object-scale-down w-[200px] h-[200px] md:w-[250px] md:h-[250px] bg-white flex justify-center place-content-center mx-auto " 
                  variant="top" src={sajena} />
                </Card>
                <Card className="txt-bg py-4">
                  <Card.Title className="text-center text-emerald-900 font-semibold underline mb-3">Sajena Dwa</Card.Title>
                  <div className="flex gap-4 justify-center mx-auto place-content-center items-center">
                  <Card.Link
                    class="text-3xl text-blue-700 text-center hover:text-cyan-500 ease-in-out duration-300 py-1"
                    href="https://www.linkedin.com/in/sajena-dwa-2bb199bb/ "
                  >
                  <i class="bi bi-linkedin"></i> 
                  </Card.Link>
                  <Card.Link class="text-3xl text-green-700 text-center hover:text-cyan-500 ease-in-out duration-300 py-1" href="https://wa.me/9779846784924">
                  <i class="bi bi-whatsapp"></i> 
                  </Card.Link>
                  <Card.Link
                    class="text-4xl text-red-700 text-center hover:text-cyan-500 ease-in-out duration-300 py-1data"
                    href="mailto:sajena.dwa@agroroutenepal.org "
                  >
                    <i class="bi bi-envelope"></i>
                  </Card.Link>
                  </div>
                  <p className="text-center mt-2 text-lg font-semibold text-cyan-800">Connect with me</p>
                </Card>
              </div>
              </section>


              {/* end */}


              </motion.section>  

            
            

        </Row>
    
      <Footer />
    </section>
  );
}

export default Teams;
