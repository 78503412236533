import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Container, Row } from "react-bootstrap";
import { file, url } from "../../url";
import { motion } from "motion/react";

function Gallery() {
  const [galleryItems, setgalleryItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${url}/photo/all`);
        setgalleryItems(res.data);
      } catch (err) {}
    };
    fetchData();
  }, []);
  console.log(galleryItems);
  return (
    <Container className="mt-5 mb-7 bg-light" fluid>
      <Row className="p-3">
        <Row>
          <Col md="12" className="text-center">
            <h3 className="logo-txt-color my-5 text-2xl font-semibold">
              GALLERY
            </h3>
          </Col>
        </Row>
        <motion.Row
          initial={{
            opacity: 0,
            y: 0,
          }}
          whileInView={{
            opacity: 1,
            y: 1,
          }}
          viewport={{
            once: true,
          }}
          transition={{
            ease: "linear",
            duration: 1,
            x: { duration: 1.8 },
          }}
          className="grid md:grid-cols-3 grid-1 mx-auto container gap-4"
        >
          {galleryItems.map((galleryItem) => (
            <div className="">
              <img
                src={galleryItem.image}
                className="h-[400px] w-[500px] my-2 bg-gray-200 p-4  rounded-md shadow-md object-scale-down"
              />
            </div>
          ))}
        </motion.Row>
      </Row>
    </Container>
  );
}

export default Gallery;
