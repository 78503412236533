import React from "react";
import { Container } from "react-bootstrap";
import AdminNavBar from "../../Components/Navbar/AdminNavBar";

function AdminPanel() {
  return (
    <div className="adminHome">
      <AdminNavBar role="ADMIN" />
      <Container className="mt-5">
        <h2 className="text-center">Welcome to the Admin Dashboard</h2>
        <p className="text-center">
          Use the navigation bar to manage blogs, images, and users.
        </p>
      </Container>
    </div>
  );
}

export default AdminPanel;
