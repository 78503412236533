import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/Navbar/NavBar";
import { NavLink } from "react-router-dom";
import news from '../Assets/Images/news.jpg'
import { file, url } from "../url";
import { motion } from "motion/react";

function AllNews() {
  const [newsItems, setnewsItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${url}/news/all`);
        setnewsItems(res.data);
      } catch (err) {}
    };
    fetchData();
  }, []);

  return (
    <section>
      <NavBar />

      <section>
         <motion.section md="12" className="text-center"
           initial={{
            opacity:0, 
      
            
          }}
         whileInView={{
           opacity:1, 
     
         }}
         viewport={{
        once: true,
          
         }}
         transition={{
          ease: "linear",
          duration: 1.6,
          x: { duration: 1.9 }
         }}
          >
            <div className="relative h-[300px] w-full  bg-gradient-to-br overflow-hidden from-gray-500 to-gray-900">
              <Card.Img  src={news} className="absolute h-[300px] w-full object-cover mix-blend-overlay hover:scale-[1.05] ease-in-out duration-500"/>
                        <h2 className="text-2xl font-semibold text-gray-300 mt-[100px] md:mt-[130px] relative  uppercase">AgriNews: Updates that Grow with You</h2>
            </div>
          </motion.section>
{/* banner end */}



{/* start of news section */}

        <motion.section
        initial={{
          opacity:0, 
         y:0
          
        }}
       whileInView={{
         opacity:1, 
       y:1
       }}
       viewport={{
      once: true,
        
       }}
       transition={{
        ease: "linear",
        duration: 1.9,
        x: { duration: 2.5 }
       }}
         className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1  gap-[70px] my-[50px] mb-[70px] mx-auto container">
          {newsItems.map((newsItem) => (
          
             <div className="lg:h-[600px] lg:w-[450px]  rounded-t-xl  bg-slate-50">
                <Card.Img  src={newsItem.thumbnail} className="lg:object-cover object-scale-down md:h-[400px] md:w-[4500px] ring-1 ring-green-500"/>
              
              <div className="bg-green-100 lg:w-[450px] rounded-b-xl ring-1 ring-green-500">
                  <Card.Title className="pt-4 text-center pb-3 font-semibold">{newsItem.title}</Card.Title>
                  <Card.Text className="text-center py-3 border-y border-green-800 text-lg">
                    <em>{newsItem.excerpt}</em>
                  </Card.Text>
      
                <NavLink to={`news/${newsItem.id}`}>
                  <Button className="w-100 bg-emerald-800 hover:bg-green-600 ease-in-out duration-300">
                    View in Detail
                  </Button>
                </NavLink>
                </div>


                </div>
          ))}
        </motion.section>

        {/* end of news section */}
      </section>
      <Footer />
    </section>
  );
}

export default AllNews;
