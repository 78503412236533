import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import logo from "../Media/AGROROUTE.png";
import CallIcon from "@material-ui/icons/Call";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { Link } from "react-router-dom";
import { motion } from "motion/react";

const Footer = () => {
  return (
    <>
    <footer className="bg-emerald-600 bg-opacity-80 ">
    
        <section className="py-10 mx-auto container lg:flex justify-between ">

          <div className="">
          <Link to='/'> <img variant="top" src={logo} className="w-[150px] my-4  mx-auto container"/> </Link> 
          </div>
      
          <div className=" my-4 " >
            <Card.Title className="textColor text-center font-semibold ">Address</Card.Title>

            <ul className="text-lg text-gray-300 ">
             <li className="py-1"><i class="bi bi-building"></i> 2nd Floor Oasis building </li>
             <li className="py-1"><i class="bi bi-geo-alt"></i> Patan Dhoka, Lalitpur Nepal</li> 
             <li className="flex gap-3 text-3xl py-1 mx-auto container text-center place-content-center justify-center">
              <a href="mailto:agroroute@agroroutenepal.org" target='_blank' className="text-red-700 hover:text-red-900 ease-in-out duration-200"><i class="bi bi-envelope"></i></a> 
              <a  href='https://np.linkedin.com/company/agroroute-nepal' target="_blank" className="text-sky-800 hover:text-sky-900 ease-in-out duration-200"><i class="bi bi-linkedin"></i></a> 
              <a href='https://www.facebook.com/p/Agroroute-Nepal-100077972273793/' target="_blank" className="text-blue-700 hover:text-blue-900 ease-in-out duration-200"><i class="bi bi-facebook"></i></a>
              </li>
           
            </ul>
            </div>
           
             
            
           
      
    
            <div className=" my-4">
            <Card.Title className="textColor text-center font-semibold ">Agroroute</Card.Title>
            <p className="py-1 text-gray-600 place-content-center items-center ">
            <i className="bi bi-caret-right-fill  text-xl "></i> 
              <Link to="/discover" className="text-lg text-gray-300">Our vision & approach</Link>
            </p>
            </div>
       

            <div className="mt-4">
            <Card.Title className="textColor text-center font-semibold ">Cooperatives</Card.Title>
           <ul className="list-disc text-lg text-gray-300 ml-4"> 
            <li className="py-1"> Cooperatives and commodities </li> 
            <li className="py-1">   Factsheets </li> 
            <li className="py-1">  Cooperative businesses </li> 
              </ul>
              </div>
         
              </section>
    

              <p className="text-center border-t border-t-green-900 py-3 text-green-200">© 2024 Agroroute Nepal. All Rights Reserved.</p>
    </footer> 
   
     </>
  );
};

export default Footer;
