import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../../../url";
import AdminNavBar from "../../../Components/Navbar/AdminNavBar";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";

function AdminNews() {
  const [news, setNews] = useState([]);

  const token = localStorage.getItem("authToken");

  const fetchNews = () => {
    axios
      .get(`${url}/news/all`) // Replace with your API endpoint
      .then((response) => {
        setNews(response.data); // Fetch the first 10 news items for demo purposes
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error Fetching News",
          text:
            error.message ||
            "An unexpected error occurred while fetching news.",
        });
      });
  };

  useEffect(() => {
    fetchNews();
  }, []);

  async function deleteIcon(id) {
    try {
      const deleteRes = await axios.delete(`${url}/news/delete/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      Swal.fire({
        icon: "success",
        title: "News Deleted",
        text: "News has been successfully deleted!",
      });

      fetchNews();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error Deleting News",
        text:
          error.message || "An unexpected error occurred while deleting news.",
      });
    }
  }

  return (
    <div className="adminHome">
      <AdminNavBar />

      <Link to="/admin/addNews">
        <button className="p-2 mb-10  mt-7 ml-2 bg-blue-500 hover:bg-blue-700 text-white rounded">
          Add More News
        </button>
      </Link>

      {/* News List */}
      <ul>
        {news.map((item, idx) => (
          <li
            key={item.id}
            className="flex justify-between items-center mb-4 bg-gray-700 p-4 rounded"
          >
            <div className="flex justify-between items-center">
              <span>{idx + 1}</span>
              <span>
                <img src={item.thumbnail} alt="" className="h-25 w-10 ml-6" />
              </span>
            </div>

            <span>
              <strong>Title:</strong> {item.title}
            </span>

            <div className="ml-4 flex gap-2">
              <Link to={`/admin/editNews/${item.id}`}>
                <button
                  onClick={() => {}}
                  className="p-2 hover:bg-gray-700 text-white rounded"
                >
                  <EditIcon />
                </button>
              </Link>

              <button
                className="p-2 bg-red-500 hover:bg-red-700 text-white rounded"
                onClick={() => deleteIcon(item.id)}
              >
                <DeleteIcon />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AdminNews;
