import React, { useState } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import axios from "axios";

import { url } from "../../../url";

const AddPhotos = () => {
  const [title, setTitle] = useState("");
  const [uploader, setUploader] = useState("");
  const [image, setImage] = useState(null);
  //const [imagePreview, setImagePreview] = useState(""); // State for image preview
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const token = localStorage.getItem("authToken");

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImage(file); // Store the file object
      //setImagePreview(URL.createObjectURL(file)); // Create a preview URL
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    if (!title || !uploader || !image) {
      setError("Please fill in all fields.");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("uploader", uploader);
    formData.append("image", image); // Append the image file

    try {
      const response = await axios.post(`${url}/photo/add`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      setMessage("Image added successfully!");
      setError("");

      // Reset form fields
      setTitle("");
      setUploader("");
      setImage(null);
      window.location.reload();
    } catch (err) {
      setError("Error adding image.");
      setMessage("");
    }
  };

  return (
    <div className="adminHome">
      <Container>
        <h1 className="text-center mb-4">Add Image</h1>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="imageTitle" className="mb-3">
            <Form.Label>Image Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter image title"
            />
          </Form.Group>

          <Form.Group controlId="imageUploader" className="mb-3">
            <Form.Label>Uploader</Form.Label>
            <Form.Control
              type="text"
              value={uploader}
              onChange={(e) => setUploader(e.target.value)}
              placeholder="Enter uploader name"
            />
          </Form.Group>

          <Form.Group controlId="imageUpload" className="mb-3">
            <Form.Label>Image Upload</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Add Image
          </Button>
        </Form>

        {message && (
          <Alert variant="success" className="mt-3">
            {message}
          </Alert>
        )}
        {error && (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        )}
      </Container>
    </div>
  );
};

export default AddPhotos;
