import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { file, url } from "../../url";
import { motion } from "motion/react";
import Swal from "sweetalert2";

function News() {
  const [newsItems, setnewsItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${url}/news/all`);
        const limitedData = res.data.slice(0, 5);
        setnewsItems(limitedData);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Error Fetching News",
          text: err.message || "An unexpected error occurred fetching  news.",
        });
      }
    };
    fetchData();
  }, []);
  console.log(newsItems);
  return (
    <div className="mx-auto container">
      <Col md="12" className="text-center mt-5">
        <h3 className="logo-txt-color text-xl md:text-2xl font-semibold mb-3">
          LATEST NEWS 
        </h3>
      </Col>
      <motion.section
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
        }}
        viewport={{
          once: true,
        }}
        transition={{
          ease: "linear",
          duration: 1,
          x: { duration: 1.5 },
        }}
        className="grid md:grid-cols-3 grid-cols-1 place-content-center mx-auto container gap-[60px] justify-center"
      >
        {newsItems.map(
          (newsItem, index) =>
            index < 3 && (
              <div className=" mt-3 ">
                <Card className=" md:w-[450px] w-[300px] rounded ">
                  <Card.Img src={newsItem.thumbnail} className="md:w-[450px] md:h-[400px] w-[300px] object-cover"/>
                  <Card.Body className="bg-gray-100 h-[110px] text-center">
                    <Card.Title>{newsItem.title}</Card.Title>
                    <Card.Text className="text-center">
                      {newsItem.excerpt}
                    </Card.Text>
                  </Card.Body>
                  <NavLink to={`news/${newsItem.id}`}>
                    <Button
                      className="w-100 hover:bg-emerald-500  hover:text-white ease-in-out duration-300"
                      variant="secondary"
                    >
                      View in Detail
                    </Button>
                  </NavLink>
                </Card>
              </div>
            )
        )}
      </motion.section>
    </div>
  );
}

export default News;
