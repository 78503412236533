import React, { useState } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AdminNavBar from "../../../Components/Navbar/AdminNavBar";
import axios from "axios";
import { url } from "../../../url";

const AddNews = () => {
  const [title, setTitle] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  //const [thumbnailPreview, setThumbnailPreview] = useState("");
  const [uploadedBy, setUploadedBy] = useState("");
  const [content, setContent] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const token = localStorage.getItem("authToken");

  const handleThumbnailChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setThumbnail(file);
    }
  };

  const handleSubmit = async (e) => {
    console.log(content);
    console.log(thumbnail);
    e.preventDefault();
    if (!title || !excerpt || !thumbnail || !uploadedBy || !content) {
      setError("Please fill in all fields.");
      return;
    }

    const newsData = {
      title,
      excerpt,
      thumbnail,
      uploadedBy,
      content,
    };

    await axios.post(`${url}/news/add`, newsData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`,
      },
    });

    setMessage("News added successfully!");
    setError("");

    // Reset form fields
    setTitle("");
    setExcerpt("");
    setUploadedBy("");
    setContent("");
  };

  return (
    <div className="adminHome">
      <AdminNavBar />
      <Container className="mt-5">
        <h2 className="text-center mb-4">Add News</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="newsTitle" className="mb-3">
            <Form.Label>News Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter news title"
            />
          </Form.Group>

          <Form.Group controlId="newsExcerpt" className="mb-3">
            <Form.Label>News Excerpt</Form.Label>
            <Form.Control
              type="text"
              value={excerpt}
              onChange={(e) => setExcerpt(e.target.value)}
              placeholder="Enter news excerpt"
            />
          </Form.Group>

          <Form.Group controlId="thumbnailUpload" className="mb-3">
            <Form.Label>Thumbnail Upload</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleThumbnailChange}
            />
          </Form.Group>

          <Form.Group controlId="uploadedBy" className="mb-3">
            <Form.Label>Uploaded By</Form.Label>
            <Form.Control
              type="text"
              value={uploadedBy}
              onChange={(e) => setUploadedBy(e.target.value)}
              placeholder="Enter your name"
            />
          </Form.Group>

          <Form.Group controlId="newsContent" className="mb-3">
            <Form.Label>News Content</Form.Label>
            <ReactQuill
              className="bg-light text-dark"
              theme="snow"
              value={content}
              onChange={setContent}
              placeholder="Write your news content here..."
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Add News
          </Button>
        </Form>

        {message && (
          <Alert variant="success" className="mt-3">
            {message}
          </Alert>
        )}
        {error && (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        )}
      </Container>
    </div>
  );
};

export default AddNews;
