import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { motion } from "motion/react";
import nepaliFarmer from "../Assets/Images/nepaliFarmer.jpg"

function Discover3() {
  return (
    <section>
      <NavBar />
      <motion.section md="12" className="text-center"
           initial={{
            opacity:0, 
      
            
          }}
         whileInView={{
           opacity:1, 
     
         }}
         viewport={{
        once: true,
          
         }}
         transition={{
          ease: "linear",
          duration: 1.6,
          x: { duration: 1.9 }
         }}
          >
            <div className="relative h-[400px] w-full  bg-gradient-to-br overflow-hidden from-gray-500 to-gray-900">
              <Card.Img  src={nepaliFarmer} className="absolute h-[400px] w-full object-cover mix-blend-overlay hover:scale-[1.05] ease-in-out duration-500"/>
          
            </div>
          </motion.section>
      <Container className="my-4 p-3  mx-auto container" >
        
        <Row>
          <Col>
           
              <motion.Card
               initial={{
                opacity:0, 
               
                
              }}
             whileInView={{
               opacity:1, 
             
             }}
             viewport={{
            once: true,
              
             }}
             transition={{
              ease: "linear",
              duration: 1.6,
              x: { duration: 1.9 }
             }}
               className="md:flex gap-5">
                <section className="w-full md:w-1/2 ring-1 ring-green-800 px-5 py-4 rounded-sm shadow-xl my-3 bg-stone-100">
                <Card.Title className="text-center mx-3 font-semibold logo-txt-color ">
                  KEY FOCUS AREAS
                </Card.Title>
                <Card.Text className="text-justify text-gray-600 custom-font-19 mt-2 mb-3 p-3">
                  The focus areas of Agroroute Nepal in relation to farmer
                  organizations, cooperatives, and small medium enterprises are
                  the following:
                  <br />
                  <br />
                  <li>
                    Strengthening governance and financial management of farmer
                    organizations, cooperatives, and small medium enterprises
                  </li>
                  <li>Business Development Services (BDS)</li>
                  <li>Marketing</li>
                  <li>Value Chain Development</li>
                  <li>
                    Lobbying and advocacy (review/research of existing legal
                    provisions
                  </li>
                  <br />
                  Consolidating its proven results and envisioning a
                  transformation in the lives of rural population who are
                  organised within the cooperatives, Agroroute Nepal intends to
                  work by focusing on the following three key areas in order to
                  enhance cooperative development in Nepal.
                  <br />
                  <div className="mt-4 ">
                  <Link className="links " to="/cbd">
                    <li className="text-emerald-600 hover:text-cyan-500 ease-in-out duration-200" > Cooperative Business Development</li>
                  </Link>
                  <Link className="links" to="/spp">
                    <li className="text-emerald-600 hover:text-cyan-500 ease-in-out duration-200">Sustainable Production and Productivity</li>
                  </Link>
                  <Link className="links" to="/occ">
                    <li className="text-emerald-600 hover:text-cyan-500 ease-in-out duration-200">Organisational Capacity of Cooperatives</li>
                  </Link>
                  </div>
                </Card.Text>
                
                </section>

                <section className="w-full md:w-1/2 my-3">
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                     
                     {/* 1 */}

                   <span className="flex gap-3 text-white">

                    <div className="bg-emerald-500 flex gap-4 p-3 rounded-md">

                      <span>
                   <i className="bi bi-person-check text-[80px]"></i>
                   </span>

                   <span>
                   <h5 className="py-2 text-center font-semibold">
                   Governance & Financial Management
                   </h5>
                   <p className="text-center">
                   Enhancing governance and financial stability for farmer organizations and SMEs.
                   </p>
                   </span>

                   </div>
                   
                   </span>
                    
                    {/* 2 */}

                      
                   <span className="flex gap-3 text-white">

<div className="bg-emerald-500 flex gap-4 p-3 rounded-md">

  <span>
<i className="bi bi-gear  text-[80px]"></i>
</span>

<span>
<h5 className="py-2 text-center font-semibold">
Business Development Services
</h5>
<p className="text-center">
Providing tailored solutions for business growth and sustainability.
</p>
</span>

</div>

</span>

{/* 3 */}

<span className="flex gap-3 text-white">

<div className="bg-emerald-500 flex gap-4 p-3 rounded-md">

  <span>
<i className="bi bi-diagram-2  text-[80px]"></i>
</span>

<span>
<h5 className="py-2 text-center font-semibold">
Marketing & Value Chains
</h5>
<p className="text-center">
Empowering marketing strategies and building efficient value chains.
</p>
</span>

</div>

</span>

{/* 4 */}

<span className="flex gap-3 text-white">

<div className="bg-emerald-500 flex gap-4 p-3 rounded-md">

  <span>
<i className="bi bi-search  text-[80px]"></i>
</span>

<span>
<h5 className="py-2 text-center font-semibold">
Advocacy & Policy Review
</h5>
<p className="text-center">
Supporting policy research and advocacy for farmer-friendly reforms.
</p>
</span>

</div>

</span>

{/* 5 */}

<span className="flex gap-3 text-white">

<div className="bg-emerald-500 flex gap-4 p-3 rounded-md">

  <span>
<i className="bi bi-person-video3  text-[80px]"></i>
</span>

<span>
<h5 className="py-2 text-center font-semibold">
Capacity Building & Training
</h5>
<p className="text-center">
Empowering individuals through skill enhancement and training programs.
</p>
</span>

</div>

</span>

{/* 6 */}

<span className="flex gap-3 text-white">

<div className="bg-emerald-500 flex gap-4 p-3 rounded-md">

  <span>
<i className="bi bi-recycle  text-[80px]"></i>
</span>

<span>
<h5 className="py-2 text-center font-semibold">
Sustainability & Innovation
</h5>
<p className="text-center">
Promoting sustainable practices and innovative solutions in agriculture.</p>
</span>

</div>

</span>



                  </div>

                </section>
              </motion.Card>
          
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
}

export default Discover3;
