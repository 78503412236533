import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/Navbar/NavBar";
import { Row, Col, Card, ListGroup } from "react-bootstrap";
import img1 from "../Assets/Results1.jpg";
import img2 from "../Assets/Results2.jpg";
import chart from "../Assets/chart.png";
import tealeaves from "../Assets/Images/tealeaves.jpg"
import { motion } from "motion/react";

function Results() {
  return( 
<section>
    <NavBar/>
    
    <motion.section md="12" className="text-center"
           initial={{
            opacity:0, 
                       
          }}
         whileInView={{
           opacity:1, 
    
         }}
         viewport={{
        once: true,
          
         }}
         transition={{
          ease: "linear",
          duration: 1.6,
          x: { duration: 1.9 }
         }}
          >
            <div className="relative h-[300px] w-full  bg-gradient-to-br overflow-hidden from-gray-500 to-gray-900">
              <Card.Img  src={tealeaves} className="absolute h-[300px] w-full object-cover mix-blend-overlay hover:scale-[1.05] ease-in-out duration-500"/>
            <h3 className="text-2xl font-semibold text-gray-300 mt-[100px] md:mt-[130px] relative  uppercase">Results That Define Us: Company Performance Overview</h3>
            </div>
          </motion.section>
      

      

    <motion.section 
    initial={{
      opacity:0, 

      
    }}
   whileInView={{
     opacity:1, 

   }}
   viewport={{
  once: true,
    
   }}
   transition={{
    ease: "linear",
    duration: 1.6,
    x: { duration: 1.9 }
   }}
    className="mx-auto container md:flex gap-[50px] justify-between">
{/* tea */}
   <div className="md:w-1/2 my-20 w-full bg-emerald-50 pt-10 px-4 rounded-lg shadow-xl ring-1 ring-green-500">
         <h3 className="pb-4 text-center text-2xl text-cyan-700 font-semibold">Facilitation to export tea to Netherlands</h3>
         <p className="text-justify text-lg text-gray-500 pb-5">
         Tinjure Tea Farmers Cooperative Society Ltd. (TTFCSL) is a tea farmers primary cooperative (PC) located in Tinjure village, approximately 100 km driving North-West from Ilam city. The organization is specialized in the collection, processing, and marketing of the fresh tea produced by their small-scale member farmers. Agroroute has facilitated TTFCSL to export tea to MAAS international, a Netherlands based organization. With the export, the cooperative gained 4-5 times higher margin compared to selling to local or Indian buyers. Similarly, MAAS supports local communities and female empowerment. Furthermore, the cooperative receives support in certification, training, knowledge, export from MAAS.
         </p>
   </div>

{/* pics */}
   <div className="md:w-1/2  w-full my-20 ring-1 ring-green-400 rounded-lg shadow-xl bg-stone-50">
          <Card.Img src={img1} className=" h-[300px] object-scale-down mb-4 pt-10"/>
          <Card.Img src={img2} className= " h-[300px] object-scale-down my-6 pb-5"/>
   </div>

    </motion.section>

      
      
 
      
          <motion.div
          initial={{
            opacity:0, 
           y:0
            
          }}
         whileInView={{
           opacity:1, 
         y:1
         }}
         viewport={{
        once: true,
          
         }}
         transition={{
          ease: "linear",
          duration: 1.6,
          x: { duration: 1.9 }
         }}
          className="bg-cyan-50">
               <h4 className="text-center text-cyan-700 text-2xl font-semibold border-t pt-4 pb-2 border-green-900">Increase in internal capitalization- a case study of Shree Dumarwana Milk Producer Cooperative Limited</h4>

                <Card.Text className="text-center mt-2  border-b border-green-900 pb-4 text-xl text-gray-600">
                <h6 className="p-2">Internal capital is a major part of the foundation of the cooperative society</h6>
                <h6 className="p-2">Farmers who own and finance the cooperative are those that use it.</h6>
                </Card.Text>
                </motion.div>


                <Card.Header><h6 className="text-center text-cyan-700 text-2xl font-semibold my-10">Why internal capital is important?</h6></Card.Header>
               
               {/* icon banners */}

               <motion.section
               initial={{
                opacity:0, 
               y:0
                
              }}
             whileInView={{
               opacity:1, 
             y:1
             }}
             viewport={{
            once: true,
              
             }}
             transition={{
              ease: "linear",
              duration: 1.6,
              x: { duration: 1.9 }
             }}
               className="mx-auto container gap-5 grid md:grid-cols-3 grid-cols-1 mt-14 mb-10">

{/* 1st */}          
                  
                  <div className="flex gap-5 place-content-center items-center bg-emerald-500 text-gray-100 px-4 py-3 rounded-lg ">
                   
                 <span className="text-[60px] ">
                 <i class="bi bi-shield-check"></i>
                 </span>

                 <span>
                  <h5 className="text-2xl pb-1 font-semibold">
                    Trust
                  </h5>
                  <p className="text-lg font-semibold">
                  Proofs that the society is adding value
                  </p>

                 </span>

                  </div>

{/* 2nd */}
<div className="flex gap-5 place-content-center items-center bg-emerald-500 text-gray-100 px-4 py-3 rounded-lg ">
                   
                   <span className="text-[60px] ">
                   <i class="bi bi-currency-exchange"></i>
                   </span>
  
                   <span>
                    <h5 className="text-2xl pb-1 font-semibold">
                    Economic Activity
                    </h5>
                    <p className="text-lg font-semibold">
                    Indicates that the activity is profitable.
                    </p>
  
                   </span>
  
                    </div>

 
{/* 3rd */}

<div className="flex gap-5 place-content-center items-center bg-emerald-500 text-gray-100 px-4 py-3 rounded-lg ">
                   
                   <span className="text-[60px] ">
                   <i class="bi bi-umbrella"></i>
                   </span>
  
                   <span>
                    <h5 className="text-2xl pb-1 font-semibold">
                    Buffer
                    </h5>
                    <p className="text-lg font-semibold">
                    Indicates that the cooperative can deal with disappointments.
                    </p>
  
                   </span>
  
                    </div>

{/* 4th */}
                 
<div className="flex gap-5 place-content-center items-center bg-emerald-500 text-gray-100 px-4 py-3 rounded-lg ">
                   
                   <span className="text-[60px] ">
                   <i class="bi bi-tree"></i>
                   </span>
  
                   <span>
                    <h5 className="text-2xl pb-1 font-semibold">
                    Sustainable
                    </h5>
                    <p className="text-lg font-semibold">
                    Shows that the cooperative is working for future development.
                    </p>
  
                   </span>
  
                    </div>    

{/* 5th */}

<div className="flex gap-5 place-content-center items-center bg-emerald-500 text-gray-100 px-4 py-3 rounded-lg ">
                   
                   <span className="text-[60px] ">
                   <i class="bi bi-piggy-bank"></i>
                   </span>
  
                   <span>
                    <h5 className="text-2xl pb-1 font-semibold">
                    Cost effective
                    </h5>
                    <p className="text-lg font-semibold">
                    Internal capital costs less than external capital.
                    </p>
  
                   </span>
  
                    </div>


{/* 6th */}


<div className="flex gap-5 place-content-center items-center bg-emerald-500 text-gray-100 px-4 py-3 rounded-lg ">
                   
                   <span className="text-[60px] ">
                   <i class="bi bi-bar-chart"></i>
                   </span>
  
                   <span>
                    <h5 className="text-2xl pb-1 font-semibold">
                    Equity
                    </h5>
                    <p className="text-lg font-semibold">
                    Leverage for external financing.
                    </p>
  
                   </span>
  
                    </div>
{/* end */}
               </motion.section>
        
          

  
      <motion.section 
      initial={{
        opacity:0, 
  
        
      }}
     whileInView={{
       opacity:1, 

     }}
     viewport={{
    once: true,
      
     }}
     transition={{
      ease: "linear",
      duration: 1.6,
      x: { duration: 1.9 }
     }}
      className="my-5 md:flex justify-between gap-4 mx-auto container">

     
           {/* img */}
                <div className="px-4  my-5 md:w-1/2 w-full">
                  <Card.Img
                    src={chart}
                  />
                </div>
            
{/* tea banner  */}
      <div className=" md:w-1/2 w-full">
            <Card className=" my-5  rounded-lg  bg-gradient-to-tr from-green-100 via-emerald-50 to-cyan-200">
              <Card.Body>
                <Card.Header><h4 className="logo-txt-color1  text-center text-2xl py-2 font-semibold">Facilitation to export tea to Netherlands</h4></Card.Header>
                <Card.Text className="text-center mt-2 mb-3 p-4 text-gray-600 text-lg" align="justified">
                <p align="justified">Shree Dumarwana Milk Producer Cooperative Limited (SDMPCL) could be a good example who really absorbed the advice on the importance of internal capitalization from Agriterra. SDMPCL, located in the central south of Nepal, was established to add the value of the milk produced by the smallholder farmers. Agriterra has been collaborating with SDMPCL since 2017 in order to professionalize the cooperative and to strive towards its goal to improve profitability for the farmers.</p>
<p align="justified">The audit report of 5 years shows the share capital increased (presented in graph below) by almost triple in 5 years period. This is happened only because of the farmers' contribution to the cooperative. The farmers had not directly deposited money at once to the cooperative, however, the cooperative deducted Nrs 1 based on the litre of the milk supply to the cooperative. The cooperative started implementing it from the third quarter of 2018.</p>
<p align="justified">The cooperative utilized the increased share capital by investing in a dairy processing plant which helps to add value to the milk. The government supported the cooperative by a grant for the installation of the processing plant where the cooperative utilized this increased capital as a matching fund. After the installation of the processing plant, the income of the cooperative increased by NRs 32 million compared to last year income (NRs 258 million). Currently, the cooperative produces pasteurized milk, yoghurt, paneer (cottage cheese), butter and ghee.</p>
 

                </Card.Text>
              </Card.Body>
            </Card>
          </div>



      </motion.section>
     
     


    
    <Footer/>
    </section>
  )
}

export default Results;
