import axios from "axios";
import React, { useState } from "react";
import { Form, Button, Container, Alert } from "react-bootstrap";
import { useHistory } from "react-router";
import { url } from "../../url";
import { Link } from "react-router-dom";
function AdminLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const validateForm = () => {
    const newErrors = {};

    if (!username.trim()) {
      newErrors.username = "Username is required";
    }

    if (!password.trim()) {
      newErrors.password = "Password is required";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();

    if (Object.keys(formErrors).length === 0) {
      const data = {
        username: username,
        password: password,
      };

      try {
        const res = await axios.post(url + "/auth/login", data);

        if (res.status === 200) {
          const token = res.data.token;
          localStorage.setItem("authToken", token);
          history.push("/admin/dashboard");
        }
      } catch (e) {
        const reqError = {};
        reqError.apiError = e.response.data.message;
        setErrors(reqError);
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <div className="loginForm">
      <Container className="mt-5">
        <Form onSubmit={handleSubmit}>
          <h2>Admin Login</h2>

          <Form.Group controlId="username" className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              value={username}
              isInvalid={!!errors.username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter username"
            />
            <Form.Control.Feedback type="invalid">
              {errors.username}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="password" className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              isInvalid={!!errors.password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          {errors.apiError && (
            <Alert variant="danger" className="mt-3">
              {errors.apiError}
            </Alert>
          )}

          <Button variant="primary" type="submit">
            Login
          </Button>
          <Link to ='/'>
            <Button  variant="primary" className="mx-3">
              Back to Home
            </Button>
            </Link>
        </Form>
      </Container>
    </div>
  );
}

export default AdminLogin;
