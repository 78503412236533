import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import logo from "../Media/AGROROUTE.png";
import { decodeJWT } from "../../utils";

const AdminNavBar = ({ role }) => {
  const history = useHistory();

  const token = localStorage.getItem("authToken");
  const [isAdmin, setAdmin] = useState(false);

  useEffect(() => {
    const jwt = decodeJWT(token);

    if (jwt.role === "ADMIN") setAdmin(true);
  }, []);

  function handleLogout() {
    localStorage.removeItem("authToken");
    history.push("/admin/login");
  }

  return (
    <Navbar bg="secondary" expand="lg" color="text-light">
      <Container fluid>
        <Navbar.Brand>
          <img
            src={logo}
            width="50"
            height="50"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Brand>Agroroute</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/admin/photos">
              Photo Management
            </Nav.Link>
            <Nav.Link as={Link} to="/admin/news">
              News Management
            </Nav.Link>
            {isAdmin ? (
              <Nav.Link as={Link} to="/admin/users">
                User Management
              </Nav.Link>
            ) : (
              <span></span>
            )}
          </Nav>
          <Nav className="flex justify-between gap-3">
            <Button onClick={handleLogout} variant="primary" className="">
              Logout 
            </Button>

            <Link to ='/' className=" ">
            <Button  variant="primary" className="w-[350px] lg:w-[150px]">
              Back to Home
            </Button>
            </Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AdminNavBar;
