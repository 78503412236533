import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import farmOne from '../Assets/Images/farmOne.jpg'
import { motion } from "motion/react";
import peer from '../Assets/Images/peer.png'
import advocacy from '../Assets/Images/advocacy.jpg'
function Discover4() {
  return (
    <section >
      <NavBar />
     
      <motion.section md="12" className="text-center"
           initial={{
            opacity:0, 
         
            
          }}
         whileInView={{
           opacity:1, 
       
         }}
         viewport={{
        once: true,
          
         }}
         transition={{
          ease: "linear",
          duration: 1.6,
          x: { duration: 1.9 }
         }}
          >
            <div className="relative h-[400px] w-full  bg-gradient-to-br overflow-hidden from-gray-500 to-gray-900">
              <Card.Img  src={farmOne} className="absolute h-[400px] w-full object-cover mix-blend-overlay hover:scale-[1.05] ease-in-out duration-500"/>
          
            </div>
          </motion.section>
        
            <motion.Card
             initial={{
              opacity:0, 
             y:0
              
            }}
           whileInView={{
             opacity:1, 
           y:1
           }}
           viewport={{
          once: true,
            
           }}
           transition={{
            ease: "linear",
            duration: 1.2,
            x: { duration: 1.5 }
           }}
             className=" mt-5 mb-4 p-3 mx-auto container" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="mx-auto container logo-txt-color text-center font-semibold">
                  ADVISE, TRAINING AND EXCHANGE
                </Card.Title>
                <Card.Text className="text-justify custom-font-19 mt-2 mb-3 p-3">
                  Agroroute Nepal’s approach focuses on the entrepreneurial
                  ambitions of organisations and cooperatives that operate in
                  the interests of farmers. With the advisory services,
                  Agroroute Nepal wants to achieve that ‘farmer-led businesses’
                  gain access to outside capital, to enable them to invest in
                  the processing or upgrading of their products and ultimately
                  to realise a better price for their members. Agroroute Nepal
                  also wants to contribute to the strengthening of farmer
                  organizations and cooperatives by targeted lobbying and
                  advocacy, because Agroroute Nepal believes that
                  entrepreneurial farmers are more successful when they
                  collaborate in cooperatives and farmers’ organisations. Their
                  success enables them to make an important contribution to
                  employment, to the fight against poverty and to the economic
                  development of their region. As shown in the figure, Agroroute
                  Nepal uses advice, exchanges and trainings to address
                  cooperatives’ specific challenges on business development,
                  governance and financial management. The key concept of this
                  approach is that Agroroute Nepal aims at transforming
                  cooperatives into self-sustaining agri-enterprises with a
                  focus on the added value of cooperatives and organisations to
                  farmer members.
                </Card.Text>
              </Card.Body>
            </motion.Card>
          
          
        <motion.section
         initial={{
          opacity:0, 
         
          
        }}
       whileInView={{
         opacity:1, 
       
       }}
       viewport={{
      once: true,
        
       }}
       transition={{
        ease: "linear",
        duration: 1.2,
        x: { duration: 1.5 }
       }}
         className="md:flex mx-auto container  gap-4">
          <div className="md:w-1/2 w-full ">
            <Card className=" mt-2 mb-3 p-3" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="text-center mx-3 logo-txt-color ">
                  PEER TO PEER LEARNING
                </Card.Title>
                <Card.Text className="text-justify custom-font-19 mt-2 mb-3 p-3  ">
                  Agroroute Nepal strongly believes that peer-to-peer learning,
                  exchanges and study tours are powerful tools for learning. As
                  a part of Agroroute Nepal advisory services Agroroute Nepal
                  provides technical field staff (business advisors),
                  peer-to-peer (farmer-to-farmer) learning, coaching tracks and
                  exchanges/study tours.
                </Card.Text>
              </Card.Body>
            </Card>
            </div>
            
            <div className="w-full md:w-1/2 mt-2 mb-4">
            <Card.Img src={peer} className="w-full object-scale-down lg:h-[288px] bg-white h-full"/>
            </div>
            </motion.section>

            <motion.section
             initial={{
              opacity:0, 
             
              
            }}
           whileInView={{
             opacity:1, 
           
           }}
           viewport={{
          once: true,
            
           }}
           transition={{
            ease: "linear",
            duration: 1.2,
            x: { duration: 1.5 }
           }} className="md:flex mx-auto container gap-4 mb-5">

            <div className="md:w-1/2 w-full">
            <Card.Img src={advocacy} className="w-full bg-white mt-2 object-scale-down lg:h-[288px] "/>
            </div>
            <div className="md:w-1/2 w-full">
            <Card className=" mt-2 mb-3 p-3" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="text-center mx-3  logo-txt-color ">
                  ADVOCACY AND LOBBY
                </Card.Title>
                <Card.Text className="text-justify custom-font-19 mt-2 mb-3 p-3">
                  Agroroute Nepal strongly believes that peer-to-peer learning,
                  exchanges and study tours are powerful tools for learning. As
                  a part of Agroroute Nepal advisory services Agroroute Nepal
                  provides technical field staff (business advisors),
                  peer-to-peer (farmer-to-farmer) learning, coaching tracks and
                  exchanges/study tours.
                </Card.Text>
              </Card.Body>
            </Card>
            </div>


            </motion.section>
     
      <Footer />
    </section>
  );
}

export default Discover4;
