import React, { useEffect, useState } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

import logo from "../Media/AGROROUTE.png";
import { nav } from "motion/react-client";
import { motion } from "motion/react";

function NavBar() {
  let [open, setOpen] = useState(true);
  const toggle =()=>{
    setOpen(!open);
  }

  return (
   
  

    <Navbar  expand="lg" className="bg-gray-200 sticky z-10 top-0 ring-1 ring-green-700 ">
      <motion.section className="flex justify-between  gap-[100px] " 
      initial={{
        opacity:0, 
        
      }}
     whileInView={{
       opacity:1, 
      
     }}
     viewport={{
    once: true,
      
     }}
     transition={{
      ease: "linear",
      duration: 0.7,
      x: { duration: 1 }
     }} 
     >
     
      <Link to="/"> 
       <span className="flex">
       <img
            src={logo}
           
            className="md:w-[80px] w-[70px] mx-4 rounded-xl hover:scale-[1.05] ease-in-out duration-500 "
            
          />

 <button className="items-center place-content-center   font-semibold hover:text-emerald-500 rounded-lg px-1 ease-in-out duration-300 text-2xl text-green-700">Agroroute</button>
</span>
</Link>
       {/* menu bar  */}
    
        <motion.div className="place-content-center items-center  " 
        >
          
          <section className="flex justify-between gap-10">
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto ">
            <NavDropdown title="Discover Agroroute" className="hover:text-bg-300 rounded-lg px-1 ease-in-out duration-300 text-lg">
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover">
                  About, Vision and Mision
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover2">
                  Theory of Change
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover3">
                  Key Focus Areas
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover4">
                  Key Approaches
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover5">
                  Target Groups
                </Nav.Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/clientNetwork" className="hover:bg-green-500 hover:text-gray-100 rounded-lg px-1 ease-in-out duration-300 text-lg mx-3">
              Client And Networks
            </Nav.Link>
{/*  */}
            <Nav.Link as={Link} to="/training" className="hover:bg-green-500 hover:text-gray-100 rounded-lg px-1 ease-in-out duration-300 text-lg mx-3">
              Our Product and Services
            </Nav.Link>

            <Nav.Link as={Link} to="/results" className="hover:bg-green-500 hover:text-gray-1000 rounded-lg px-1 ease-in-out duration-300 text-lg mx-3">
              Results
            </Nav.Link>
            <Nav.Link as={Link} to="/allnews" className="hover:bg-green-500 hover:text-gray-100 rounded-lg px-1 ease-in-out duration-300 text-lg mx-3">
              News
            </Nav.Link>
            <Nav.Link as={Link} to="/team" className="hover:bg-green-500 hover:text-gray-100 rounded-lg px-1 ease-in-out duration-300 text-lg mx-3">
              Our Team
            </Nav.Link>
          </Nav>

        </Navbar.Collapse>
        

         {/* login */}
         <div className="place-content-center items-center text-3xl lg:block hidden ">
  <Link to='/admin/login'>
          <i className="bi bi-person-fill text-green-900 hover:text-emerald-600 duration-300 ease-in-out"></i>
          </Link>
          </div>
        </section>


{/* mobile nav */}
        <nav className='' 
        >
        <motion.button onClick={toggle} className='block lg:hidden py-3 text-4xl hover:text-sky-400 text-green-800 text-opacity-80 px-2 ease-in-out duration-300'
       >

        <div>{open ? <i className="bi bi-list " ></i> : <i className="bi bi-x-lg " ></i>}</div>
        </motion.button>


        <motion.ul
         initial={{
          opacity: 0,
          scale: 1,
        }}
       whileInView={{
        opacity: 1,
        scale: 1,
       }}
       viewport={{
        once: false,
        
       }}
       transition={{
        ease: "linear",
        duration: 0.5,
        x: { duration: 0.05 }
       }} 
         className={`   ${open ? 'hidden' : ''} bg-gray-200 
            rounded-b-md  absolute h-[600px] md:top-28 mt-2 ring-1 ring-green-700 left-[-48px] overflow-auto lg:hidden text-xl font-semibold px-5 py-5 mx-5 text-left flex flex-col w-full md:text-center items-center gap-5 place-self-center 
            border-gray-500 border-opacity-20 border-y border-x transform transition-transform `}>
               <Nav className="mx-auto ">
            <NavDropdown title="Discover Agroroute" className="hover:text-bg-300 rounded-lg px-1 ease-in-out duration-300 text-lg">
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover">
                  About, Vision and Mision
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover2">
                  Theory of Change
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover3">
                  Key Focus Areas
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover4">
                  Key Approaches
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover5">
                  Target Groups
                </Nav.Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/clientNetwork" className="hover:bg-green-50 rounded-lg px-1 ease-in-out duration-300 text-lg mx-3">
              Client And Networks
            </Nav.Link>
{/*  */}
            <Nav.Link as={Link} to="/training" className="hover:bg-green-50 rounded-lg px-1 ease-in-out duration-300 text-lg mx-3">
              Our Product and Services
            </Nav.Link>

            <Nav.Link as={Link} to="/results" className="hover:bg-green-50 rounded-lg px-1 ease-in-out duration-300 text-lg mx-3">
              Results
            </Nav.Link>
            <Nav.Link as={Link} to="/allnews" className="hover:bg-green-50 rounded-lg px-1 ease-in-out duration-300 text-lg mx-3">
              News
            </Nav.Link>
            <Nav.Link as={Link} to="/team" className="hover:bg-green-50 rounded-lg px-1 ease-in-out duration-300 text-lg mx-3">
              Our Team 
            </Nav.Link>
           
            <Nav.Link as={Link} to="/admin/login" className="hover:bg-green-50 rounded-lg px-1 ease-in-out duration-300 text-lg mx-3">
              Login  &nbsp; <i class="bi bi-person"></i>
            </Nav.Link>
          </Nav>
            </motion.ul>


      </nav>
        </motion.div>
      </motion.section>
      
    </Navbar>
  );
}

export default NavBar;
