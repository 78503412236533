import React, { useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { url } from "../../url";

const TokenValidator = () => {
  const navigate = useHistory();

  // Function to check token validity
  const validateToken = async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      return;
    }

    try {
      // Send a request to the backend to validate the token
      await axios.get(url + `/auth/validate/${token}`);
      // If the request is successful, the token is valid
    } catch (error) {
      console.error("Token is invalid or expired:", error);
      logoutUser();
    }
  };

  // Function to log out the user
  const logoutUser = () => {
    localStorage.removeItem("authToken");
    navigate.push("/"); // Redirect to login page
  };

  useEffect(() => {
    // Validate token on component mount
    validateToken();

    // Set up periodic token validation (e.g., every 5 minutes)
    const intervalId = setInterval(validateToken, 1 * 60 * 1000); // 5 minutes

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return null; // This component doesn't render anything
};

export default TokenValidator;
