import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import NavBar from "../Components/Navbar/NavBar";
import sachin from "../Assets/demo.mp4";
import demo2 from "../Assets/demo2.mp4";
import demo3 from "../Assets/demo3.mp4";
import { useHistory, useParams } from "react-router-dom";
import { file, url } from "../url";
import Footer from "../Components/Footer/Footer";
import newsTwo from '../Assets/Images/newsTwo.jpg'
import { motion } from "motion/react";

function NewsPage() {
  let videoArray = [];

  videoArray.push(sachin);
  videoArray.push(demo2);
  videoArray.push(demo3);

  const [newsItems, setnewsItems] = useState([]);
  const { id } = useParams();

  const formatDate = (item) => {
    console.log(item);
    const date = new Date(item.dateCreated);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return { ...item, day: day, month: month, year: year };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${url}/news/all/${id}`);
        const news = formatDate(res.data);
        console.log(news);
        setnewsItems(news);
        console.log(newsItems);
      } catch (err) {}
    };
    fetchData();
  }, []);
  return (
    <section >
      <NavBar />
      <motion.section className="text-center"
           initial={{
            opacity:0, 
      
            
          }}
         whileInView={{
           opacity:1, 
     
         }}
         viewport={{
        once: true,
          
         }}
         transition={{
          ease: "linear",
          duration: 1.6,
          x: { duration: 1.9 }
         }}
          >
            <div className="relative h-[300px] w-full  bg-gradient-to-br overflow-hidden from-gray-500 to-gray-900">
              <Card.Img  src={newsTwo} className="absolute h-[300px] w-full object-cover mix-blend-overlay hover:scale-[1.05] ease-in-out duration-500"/>
                        <h2 className="text-2xl font-semibold text-gray-300 mt-[130px] relative  uppercase">Dig Deeper: Full Story Ahead</h2>
            </div>
          </motion.section>
    
   

        <div className="mt-5">
          <Card border="secondary">
            <Card.Body className="mx-auto container italic text-xm text-gray-600">
              By <strong> {newsItems.uploader} </strong> |
              <strong>
                {" "}
                {newsItems.day}-{newsItems.month}-{newsItems.year}{" "}
              </strong>{" "}
              | Agroroute Nepal
            </Card.Body>
          </Card>
        </div>
   


   

{/*start of news details  */}
     <motion.section
  initial={{
    opacity:0, 
 y:0,
    
  }}
 whileInView={{
   opacity:1, 
y:1,
 }}
 viewport={{
once: true,
  
 }}
 transition={{
  ease: "linear",
  duration: 1.6,
  x: { duration: 2 }
 }}
      className="lg:flex justify-center gap-5 mx-auto container my-10">
      {/* pics */}
        <div className="lg:w-[50%] w-full">
          <img
          
            src={newsItems.thumbnail}
            className="w-full my-3"
          />
        </div>

         {/* details */}
     
  <div className="my-3  p-4  lg:w-[50%] w-full">

     {/* title starts */}
     <Col md="" className="">
          <h1 className="text-center text-2xl font-semibold text-green-900 " >
            {newsItems.title}
          </h1>
          <p className="text-center text-xl my-4 border-b border-gray-800 pb-4">
           {newsItems.excerpt}
          </p>
        </Col>

      {/* title end */}
        <span
          md="auto"
          className="text-lg text-justify text-gray-600"
          dangerouslySetInnerHTML={{ __html: newsItems.content }}
        ></span>
</div>
      </motion.section>



{/* end */}
     
      <Footer />
    </section>
  );
}

export default NewsPage;
