import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import video from "../Media/video-agriterra.mp4";
import { motion } from "motion/react";
import { Banner } from "./Banner";

function Home() {
  return (
    <section>
      <motion.div 
      initial={{
        opacity:0, 
        scale: 0
        
      }}
     whileInView={{
       opacity:1, 
       scale:1
      
     }}
     viewport={{
    once: true,
      
     }}
     transition={{
      ease: "linear",
      duration: 1,
      x: { duration: 1.5 }
     }} >
          <video className="lg:w-[1400px] mx-auto container w-[350px] mt-10  " controls autoplay>
            <source src={video} type="video/mp4" />
          </video>
          </motion.div> 

          <motion.section
          initial={{
            opacity:0, 
          
            
          }}
         whileInView={{
           opacity:1, 
          
          
         }}
         viewport={{
        once: true,
          
         }}
         transition={{
          ease: "linear",
          duration: 1,
          x: { duration: 1.5 }
         }}>
          <Banner/>
          </motion.section>

    </section>

  );
}

export default Home;
