import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row, ListGroup } from "react-bootstrap";
import oldFarm from '../Assets/Images/oldFarm.jpg'
import { motion } from "motion/react";

function Training() {
  return (
    <section>
      <NavBar />
 
      <motion.section md="12" className="text-center"
           initial={{
            opacity:0, 
          
            
          }}
         whileInView={{
           opacity:1, 
      
         }}
         viewport={{
        once: true,
          
         }}
         transition={{
          ease: "linear",
          duration: 1.6,
          x: { duration: 1.9 }
         }}
          >
            <div className="relative h-[410px] md:h-[300px] w-full  bg-gradient-to-br overflow-hidden from-gray-500 to-gray-900">
              <Card.Img  src={oldFarm} className="absolute opacity-80 h-[410px] md:h-[300px] w-full object-cover mix-blend-overlay hover:scale-[1.05] ease-in-out duration-500"/>
            <h3 className="md:text-4xl text-lg font-semibold text-gray-300 mt-[60px] md:mt-[70px] relative  uppercase">Empowering Agriculture Cooperatives for Sustainable Growth</h3>
            <p className="text-justify md:text-lg text-sm  relative text-gray-400 mx-auto container py-2 md:py-4">
              At the heart of our mission lies the commitment to empowering agriculture cooperatives to thrive. We offer a comprehensive suite of services designed to address the core challenges faced by cooperatives, focusing on four key pillars: Institutional Capacity and Development, Governance and Human Resource Management, Financial Management, and Business Development. Each pillar is tailored to build resilience, enhance efficiency, and drive sustainable progress, ensuring that your cooperative can navigate today's challenges and seize tomorrow's opportunities with confidence.</p>
            </div>
          </motion.section>
{/* end of banner img */}


      <motion.section
initial={{
  opacity:0, 
 y:0
  
}}
whileInView={{
 opacity:1, 
y:1
}}
viewport={{
once: true,

}}
transition={{
ease: "linear",
duration: 1.6,
x: { duration: 2.2}
}}
       className="my-5 mx-auto container grid grid-cols-1 md:grid-cols-2 gap-5">
         

         {/* 1 */}
         <div className="bg-green-50 ring-1 ring-green-300 rounded-lg shadow-md">
                <Card.Title className=" mx-3 logo-txt-color md:flex gap-20">
              <span className="items-center place-content-center justify-center flex md:mt-0 mt-7 text-[80px] "> <i className="bi bi-diagram-3 bg-emerald-600 text-white rounded-full
              px-6 py-3 opacity-85 md:ml-[45px] "></i>   </span> 
                
                <span>
                  <h4 className=" text-center mt-6">INSTITUTIONAL CAPACITY AND DEVELOPMENT
                  </h4>
                <ul className="mx-2 text-gray-500 custom-font-19 mt-2 mb-3 p-3 list-disc text-lg">
                  <li>Managing your Agriculture Cooperatives</li>
                  <li>Aspire</li>
                  <li>Strategic planning</li>
                  <li>Farmer advocacy consultation tools</li>
                  <li>Scoping/Cooperatives assessment</li>
                  <li>Proposal writing</li>
                </ul>
                </span>
                </Card.Title>
                </div>  
          
          {/* 2 */}

          <div className="bg-green-50 ring-1 ring-green-300 rounded-lg shadow-md">
                <Card.Title className=" mx-3 logo-txt-color md:flex gap-20">
              <span className="items-center place-content-center justify-center flex md:mt-0 mt-7 text-[80px] "> <i className="bi bi-people bg-emerald-600 text-white rounded-full
              px-6 py-3 opacity-85 md:ml-[45px] "></i>   </span> 
                
                <span>
                  <h4 className=" text-center mt-6">GOVERNANCE AND HRM
                  </h4>
                <ul className="mx-2 text-gray-500 custom-font-19 mt-2 mb-3 p-3 list-disc text-lg">
                  <li>Cooperative governance</li>
                  <li>Leadership and people management training</li>
                  <li>Account supervisory board training</li>
                  <li>Member commitment workshop</li>
                  <li>Female leadership</li>
                  <li>Youth workshop</li>
                  <li>Members commitment</li>
                </ul>
                </span>
                </Card.Title>
                </div> 
            
        {/* 3 */}
        
        <div className="bg-green-50 ring-1 ring-green-300 rounded-lg shadow-md">
                <Card.Title className=" mx-3 logo-txt-color md:flex gap-20">
              <span className="items-center place-content-center justify-center flex md:mt-0 mt-7 text-[80px] "> <i className="bi bi-cash-stack bg-emerald-600 text-white rounded-full
              px-6 py-3 opacity-85 md:ml-[45px] "></i>   </span> 
                
                <span>
                  <h4 className=" text-center mt-6">FINANCIAL MANAGEMENT
                  </h4>
                <ul className="mx-2 text-gray-500 custom-font-19 mt-2 mb-3 p-3 list-disc text-lg">
                  <li>Financial management for agriculture cooperatives</li>
                  <li>Financial management for saving and credit cooperatives</li>
                  <li>Strategic financial management</li>
                  <li>Account and taxation</li>
                  <li>Microfinance Training of Trainers</li>
                  <li>Internal Capitalization</li>
                </ul>
                </span>
                </Card.Title>
                </div> 
           
        
        {/* 4 */}
        <div className="bg-green-50 ring-1 ring-green-300 rounded-lg shadow-md ">
                <Card.Title className=" mx-3 logo-txt-color md:flex gap-20">
              <span className="items-center place-content-center justify-center flex md:mt-0 mt-7 text-[80px] "> <i className="bi bi-bar-chart-line bg-emerald-600 text-white rounded-full
              px-6 py-3 opacity-85 md:ml-[45px] "></i>   </span> 
                
                <span>
                  <h4 className=" text-center mt-6">BUSINESS DEVELOPMENT
                  </h4>
                <ul className="mx-2 text-gray-500 custom-font-19 mt-2 mb-3 p-3 list-disc text-lg">
                  <li>Basic Marketing Training</li>
                  <li>Agi coop marketing training</li>
                  <li>Sustainable service workshop</li>
                  <li>Value creator</li>
                  <li>Record keeping</li>
                  <li>Business planning</li>
                </ul>
                </span>
                </Card.Title>
                </div>  

                </motion.section>
        {/* <Row className="">
          <Col className="" md={{ span: 6, offset: 3 }}>
            <Card className="my-2 p-1 rounded">
              <Card.Body>
                <Card.Title className="text-left mx-3 logo-txt-color ">
                  TRAINING COOPERATIVES
                </Card.Title>
                <Card.Text className="text-left mx-2 mt-2 mb-3 p-1">
                  Agroroute has standardized training products that are
                  developed and used by Agriterra. Agroroute had an MoU with
                  Agriterra to use the training products of it.
                </Card.Text>

                <Card.Header>
                  <h4 className="logo-txt-color1">We provide trainings on:</h4>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>Management and organisation</ListGroup.Item>
                  <ListGroup.Item>Governance and leadership</ListGroup.Item>
                  <ListGroup.Item>Financial management</ListGroup.Item>
                  <ListGroup.Item>Business development</ListGroup.Item>
                  <ListGroup.Item>Lobby and advocacy</ListGroup.Item>
                  <ListGroup.Item>
                    For more information, please visit{" "}
                    <a
                      className="teamdata"
                      target="_blank"
                      href="https://training.agriterra.org/training"
                    >
                      Agriterra
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
   
      <Footer />
      </section>
  );
}

export default Training;
