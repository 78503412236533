import { section } from 'motion/react-client'
import React from 'react'

export const Banner = () => {
  return (
    <section className='mx-auto container md:mt-20'>
     
     <div className='md:flex '>
       
<div>
    <section className='md:flex'>
{/*  */}


 <span className='text-2xl font-semibold text-emerald-700 w-full md:w-[30%] md-2  md:bg-gray-200 items-center place-content-center py-3 px-5 '>
{/* 1 */}
    <div className='flex gap-3 py-3 px-2'>
<i className="bi bi-arrow-right "></i> <p> International specialist in cooperative development</p>
</div>
{/* 2 */}
<div className='flex gap-3 py-3 px-2'>
<i className="bi bi-arrow-right "></i> <p> We reach almost 700.000 farmers to create cooperative businesses</p>
</div>

</span>
{/* icons */}

<span className='w-full md:w-[70%]  md:flex'>
    {/* 1 */}
     <div className='text-center md:w-[25%] bg-emerald-400 text-gray-100 px-2 py-3 md:py-0 '>
       <i className="bi bi-bullseye text-[90px]"></i>
    
       <h4 className='text-2xl font-semibold'>
       Focused
       </h4 >
       <p className='text-xl my-3'>Unique experience and knowledge of cooperatives</p>
      
        </div>

        {/* 2 */}
        <div className='text-center md:w-[25%] bg-emerald-500 text-gray-100 px-2 py-3 md:py-0 '>
       <i className="bi bi-award text-[90px]"></i>
    
       <h4 className='text-2xl font-semibold'>
       Quality
       </h4 >
       <p className='text-xl my-3'>Peer-to-peer Agripool expert advice, training and exchange </p>
      
        </div>
        {/* 3 */}
        <div className='text-center md:w-[25%] bg-emerald-600 text-gray-100 px-2 py-3 md:py-0 '>
       <i className="bi bi-globe-central-south-asia text-[90px]"></i>
    
       <h4 className='text-2xl font-semibold'>
       Local
       </h4 >
       <p className='text-xl my-3'>Strong local presence through region offices</p>
      
        </div>
        {/* 4 */}
        <div className='text-center md:w-[25%] bg-emerald-700 text-gray-100 px-2 py-3 md:py-0 '>
       <i className="bi bi-people text-[90px]"></i>
    
       <h4 className='text-2xl font-semibold'>
       Connected
       </h4 >
       <p className='text-xl my-3'>Extensive network in cooperative sectors worldwide</p>
      
        </div>
</span>

</section>
</div>


     </div>
    </section>
  )
}
