import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/Navbar/NavBar";
import clients from "../Assets/Images/clients.png";
import agriterra from "../Assets/Images/agriterra.png";
import lutheran from "../Assets/Images/agro.png";
import icco from "../Assets/Images/icco.jpg";
import agro from "../Assets/Images/lutheran.png";
import seepje from "../Assets/Images/seepje.png";
import maas from "../Assets/Images/maas.png";
import dpa from "../Assets/Images/dpa.png";
import CC from "../Assets/Images/cooperative_consultancy.png";
import cordaid from "../Assets/Images/cordaid.png";
import AAC from "../Assets/Images/AAC.jpg"
import { motion } from "motion/react";
import ladyFarmer from "../Assets/Images/ladyFarmer.jpg"

function ClientNetwork() {
  return (
    <section>
      <NavBar />
      
     
          <motion.section md="12" className="text-center"
           initial={{
            opacity:0, 
           y:0
            
          }}
         whileInView={{
           opacity:1, 
         y:1
         }}
         viewport={{
        once: true,
          
         }}
         transition={{
          ease: "linear",
          duration: 1.6,
          x: { duration: 1.9 }
         }}
          >
            <div className="relative h-[300px] w-full  bg-gradient-to-br overflow-hidden from-gray-500 to-gray-900">
              <Card.Img  src={ladyFarmer} className="absolute h-[300px] w-full object-cover mix-blend-overlay hover:scale-[1.05] ease-in-out duration-500"/>
            <h3 className="text-2xl font-semibold text-gray-300 mt-[100px] md:mt-[130px] relative  uppercase">About our Esteemed Clients and Trusted Networks</h3>
            </div>
          </motion.section>
   
        <Row className="d-flex justify-content-center p-3">
          <Col>
            <Card className=" my-2 p-3 mx-auto container shadow-xl rounded-xl">
              <Card.Body>
                <Card.Title className="text-center font-semibold logo-txt-color mt-4 underline ">
                  PRIMARY COOPERATIVES
                </Card.Title>
                <Card.Text className="text-center mt-2 mb-3 p-3 we-work ">
                  {/* We work with total 15 primary agricultural cooperatives,
                district cooperative unions and national level cooperative
                federations. */}
                </Card.Text>
                {/* <Card.Img
                className=" p-3 card-img-top"
                variant="top"
                height="500"
                src={clients}
              /> */}
                <motion.section
                 initial={{
                  opacity:0, 
                 y:0
                  
                }}
               whileInView={{
                 opacity:1, 
               y:1
               }}
               viewport={{
              once: true,
                
               }}
               transition={{
                ease: "linear",
                duration: 1.6,
                x: { duration: 1.9 }
               }}
                 className="md:flex justify-between md:mx-20  gap-5  ">
                  {/* 1st list*/}
                  <ul>


                    {/*1st  */}

                    <li className="py-3 my-3 text-lg md:h-[250px] text-cyan-700 items-center place-content-center bg-green-50 px-5 rounded-xl shadow-md ring-1 ring-green-400" >
                     
                      <strong>
                        {" "}
                        <i class="bi bi-caret-right-fill"></i> Adhunik Agricultural Cooperative Ltd (AACL)
                      </strong>
                      <br />
                      <div className="py-2 text-gray-500 font-semibold  ">
                      <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Members : 2.414 </p></span>
                      <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Product: Vegetables  </p></span>
                      <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Turnover 2020: NPR 41 million </p></span>
                      </div>
                     
                    </li>


                    {/* 2nd */}

                    <li className="py-3 my-3 text-lg md:h-[250px] text-cyan-700 items-center place-content-center bg-green-50 px-5 rounded-xl shadow-md ring-1 ring-green-400" >
                     
                     <strong>
                       {" "}
                       <i class="bi bi-caret-right-fill"></i> Dumarwana Milk Producer Cooperative Ltd.
                     </strong>
                     <br />
                     <div className="py-2 text-gray-500 font-semibold  ">
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Members : 2.128 </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Product: Dairy  </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Turnover 2020: NPR 292 million  </p></span>
                     </div>
                    
                   </li>

                    {/* 3rd */}

                    <li className="py-3 my-3 text-lg md:h-[250px] text-cyan-700 items-center place-content-center bg-green-50 px-5 rounded-xl shadow-md ring-1 ring-green-400" >
                     
                      <strong>
                        {" "}
                        <i class="bi bi-caret-right-fill"></i> Annapurna Dairy Producer Cooperative Ltd.
                      </strong>
                      <br />
                      <div className="py-2 text-gray-500 font-semibold  ">
                      <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Members : 674  </p></span>
                      <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Product: Dairy  </p></span>
                      <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Turnover 2020: NPR 179 million  </p></span>
                      </div>
                     
                    </li>


                    {/* 4th */}

                    <li className="py-3 my-3 text-lg md:h-[250px] text-cyan-700 items-center place-content-center bg-green-50 px-5 rounded-xl shadow-md ring-1 ring-green-400" >
                     
                     <strong>
                       {" "}
                       <i class="bi bi-caret-right-fill"></i> Bajrabarahi Small Farmers Agricultural Cooperative Ltd (BSFACL)
                     </strong>
                     <br />
                     <div className="py-2 text-gray-500 font-semibold  ">
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Members : 1.301  </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Product: Potatoes   </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Turnover 2020: NPR 45 million </p></span>
                     </div>
                    
                   </li>


                  </ul>
{/* 2nd list */}
                  <ul>
                    {/* 1st */}
                    <li className="py-3 my-3 text-lg md:h-[250px] text-cyan-700 items-center place-content-center bg-green-50 px-5 rounded-xl shadow-md ring-1 ring-green-400" >
                     
                     <strong>
                       {" "}
                       <i class="bi bi-caret-right-fill"></i> Dumarwana Small Farmers Agricultural Cooperative Ltd. (DSFACL)
                     </strong>
                     <br />
                     <div className="py-2 text-gray-500 font-semibold  ">
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Members : : 9.784 </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Product: Rice and other crops   </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Turnover 2020:NPR 191 million </p></span>
                     </div>
                    
                   </li>


                   {/* 2nd */}

                   
                   <li className="py-3 my-3 text-lg md:h-[250px] text-cyan-700 items-center place-content-center bg-green-50 px-5 rounded-xl shadow-md ring-1 ring-green-400" >
                     
                     <strong>
                       {" "}
                       <i class="bi bi-caret-right-fill"></i> Kamdhenu Dairy Development Cooperative (KDDC)
                     </strong>
                     <br />
                     <div className="py-2 text-gray-500 font-semibold  ">
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Members : 395   </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Product: Dairy    </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Turnover 2020: NPR 98 million </p></span>
                     </div>
                    
                   </li>

                   {/* 3rd */}

                   <li className="py-3 my-3 text-lg md:h-[250px] text-cyan-700 items-center place-content-center bg-green-50 px-5 rounded-xl shadow-md ring-1 ring-green-400" >
                     
                     <strong>
                       {" "}
                       <i class="bi bi-caret-right-fill"></i> Prithivinagar Small Farmers Agricultural Cooperative Ltd. (PSFACL)
                     </strong>
                     <br />
                     <div className="py-2 text-gray-500 font-semibold  ">
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Members : 2.187  </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Product: Tea    </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Turnover 2020: NPR 96 million </p></span>
                     </div>
                    
                   </li>


                    {/* 4th */}
                    <li className="py-3 my-3 text-lg md:h-[250px] text-cyan-700 items-center place-content-center bg-green-50 px-5 rounded-xl shadow-md ring-1 ring-green-400" >
                     
                     <strong>
                       {" "}
                       <i class="bi bi-caret-right-fill"></i> Rupa Lake Restoration and Fishery Cooperative Ltd. (RLRFC)
                     </strong>
                     <br />
                     <div className="py-2 text-gray-500 font-semibold  ">
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Members : 804   </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Product: Fish   </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Turnover 2020: NPR 27 million </p></span>
                     </div>
                    
                   </li>

                   {/* 5th */}
                   <li className="py-3 my-3 text-lg md:h-[250px] text-cyan-700 items-center place-content-center bg-green-50 px-5 rounded-xl shadow-md ring-1 ring-green-400" >
                     
                     <strong>
                       {" "}
                       <i class="bi bi-caret-right-fill"></i> Tinjure Tea Cooperative (TTC)
                     </strong>
                     <br />
                     <div className="py-2 text-gray-500 font-semibold  ">
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Members : 254  </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Product: Fish    </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center ">Turnover 2020: NPR 8 million </p></span>
                     </div>
                    
                   </li>


                  </ul>
                </motion.section>
                {/* end of 1st section */}


              </Card.Body>
    
            </Card>
          </Col>
        </Row>



{/* SECOND BANNER Alliance*/}

<Row className="d-flex justify-content-center p-3">
          <Col>
            <Card className=" my-2 p-3 mx-auto container shadow-xl rounded-xl">
              <Card.Body>
                <Card.Title className="text-center font-semibold logo-txt-color mt-4 underline ">
                Union and Alliance: Strength in Collaboration
                </Card.Title>
                <Card.Text className="text-center mt-2 mb-3 p-3 we-work ">
                  {/* We work with total 15 primary agricultural cooperatives,
                district cooperative unions and national level cooperative
                federations. */}
                </Card.Text>
                {/* <Card.Img
                className=" p-3 card-img-top"
                variant="top"
                height="500"
                src={clients}
              /> */}
                <motion.section
                 initial={{
                  opacity:0, 
                 y:0
                  
                }}
               whileInView={{
                 opacity:1, 
               y:1
               }}
               viewport={{
              once: true,
                
               }}
               transition={{
                ease: "linear",
                duration: 1.6,
                x: { duration: 1.9 }
               }}
                 className="md:flex justify-between md:mx-20  gap-5  ">
                  {/* 1st list*/}
                  <ul>


                    {/*1st  */}

                    <li className="py-3 my-3 text-lg  text-cyan-700 items-center place-content-center bg-green-50 px-5 rounded-xl shadow-md ring-1 ring-green-400" >
                     
                      <strong>
                        {" "}
                        <i class="bi bi-caret-right-fill"></i> Central Tea Cooperative Federation (CTCF)
                      </strong>
                      <br />
                      <div className="py-2 text-gray-500 font-semibold  ">
                      <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> -Organization Members : 10 </p></span>
                      <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Famer members : 6115  </p></span>
                      <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Product: Tea </p></span>
                      <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Turnover 2020 : NPR 3 million </p></span>
                      </div>
                     
                    </li>


                    {/* 2nd */}

                    <li className="py-3 my-3 text-lg text-cyan-700 items-center place-content-center bg-green-50 px-5 rounded-xl shadow-md ring-1 ring-green-400" >
                     
                     <strong>
                       {" "}
                       <i class="bi bi-caret-right-fill"></i> Lalitpur District Milk Producer Cooperative Union (LDMPCU)
                     </strong>
                     <br />
                     <div className="py-2 text-gray-500 font-semibold  ">
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Organization Members : 54 </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Famer members : 3416  </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Product: Diary  </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Turnover 2020 : NPR 98 million  </p></span>
                     </div>
                    
                   </li>

                    {/* 3rd */}

                    <li className="py-3 my-3 text-lg text-cyan-700 items-center place-content-center bg-green-50 px-5 rounded-xl shadow-md ring-1 ring-green-400" >
                     
                      <strong>
                        {" "}
                        <i class="bi bi-caret-right-fill"></i> Nepal Agriculture CooperativeCentral Federation (NACCF)
                      </strong>
                      <br />
                      <div className="py-2 text-gray-500 font-semibold  ">
                      <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Organization Members : 995 </p></span>
                      <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Famer members : 970.000 </p></span>
                      <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Product: Different crops  </p></span>
                      <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Turnover 2020 : NPR 66 million  </p></span>
                      </div>
                     
                    </li>


                   

                    


                  </ul>
{/* 2nd list */}
                  <ul>
                    {/* 1st */}
                    <li className="py-3 my-3 text-lg text-cyan-700 items-center place-content-center bg-green-50 px-5 rounded-xl shadow-md ring-1 ring-green-400" >
                     
                     <strong>
                       {" "}
                       <i class="bi bi-caret-right-fill"></i>District Agriculture Cooperative Union (DACU), Makwanpur
                     </strong>
                     <br />
                     <div className="py-2 text-gray-500 font-semibold  ">
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Organization Members : 51 </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center">Famer members : 25.100 </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Product: Different crops </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Turnover 2020 : NPR 2 million </p></span>
                     </div>
                    
                   </li>


                   {/* 2nd */}

                   
                   <li className="py-3 my-3 text-lg text-cyan-700 items-center place-content-center bg-green-50 px-5 rounded-xl shadow-md ring-1 ring-green-400" >
                     
                     <strong>
                       {" "}
                       <i class="bi bi-caret-right-fill"></i> Sindhupalchow Coffee Cooperative Union (SCCU)
                     </strong>
                     <br />
                     <div className="py-2 text-gray-500 font-semibold  ">
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Organization Members : 10  </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center"></i><p className="items-center place-content-center"> Famer members : 700 </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Product: Coffee </p></span>
                     <span className="flex  gap-1 py-1"><i className="bi bi-dot text-4xl items-center place-content-center "></i> <p className="items-center place-content-center">Turnover 2020 : NPR 6 million </p></span>
                     </div>
                    
                   </li>

                   


                  </ul>
                </motion.section>
                {/* end of 1st section */}


              </Card.Body>
    
            </Card>
          </Col>
        </Row>


     

      <Container className="mt-5 bg-light" fluid>
        <Row className="p-3">
          <Col md="12" className="text-center">
            <h3 className="logo-txt-color uppercase font-semibold text-2xl py-4">Networks: Connecting Possibilities</h3>
          </Col>
        </Row>

<motion.section

initial={{
  opacity:0, 
 y:0
  
}}
whileInView={{
 opacity:1, 
y:1
}}
viewport={{
once: true,

}}
transition={{
ease: "linear",
duration: 1.6,
x: { duration: 1.9 }
}}

className="md:ml-[150px] ">
        <Row className="md:grid-cols-4 grid-cols-2 grid p-3 mx-auto container ">
          
            
              <Card.Body>
                <Card.Img src={agriterra} className="md:w-[200px] w-[150px] "/>
              </Card.Body>
          
        
              <Card.Body>
                <Card.Img  src={lutheran} className="md:w-[200px] w-[150px]" />
              </Card.Body>
           

          
              <Card.Body>
                <Card.Img  src={agro} className="md:w-[200px] w-[150px]" />
              </Card.Body>
            
          
              <Card.Body>
                <Card.Img  src={seepje} className="md:w-[200px] w-[150px]" />
              </Card.Body>
           
         
              <Card.Body>
                <Card.Img  src={dpa} className="md:w-[200px] w-[150px]" />
              </Card.Body>
           
         
              <Card.Body>
                <Card.Img src={cordaid}  className="md:w-[200px] w-[150px]"/>
              </Card.Body>
           
         
              <Card.Body>
                <Card.Img  src={CC} className="md:w-[200px] w-[150px]" />
              </Card.Body>
           
          
              <Card.Body>
                <Card.Img  src={maas}  className="md:w-[200px] w-[150px]"/>
              </Card.Body>
          
        </Row>
        </motion.section>
      </Container>
      <Footer />
    </section>
  );
}

export default ClientNetwork;
